(function (global, factory) {
  typeof exports === "object" && typeof module !== "undefined"
    ? (module.exports = factory())
    : typeof define === "function" && define.amd
    ? define(factory)
    : (global.ssowizard = factory());
})(this, function () {
  "use strict";

  var ssowizard = function (id) {
    var self = this,
      modal,
      tabs,
      tabCount,
      currentGroup,
      contents;
    self.id = id;

    $(self.id).click(function (e) {
      e.stopPropagation();
      self.init(this);
    });

    this.init = function (button) {
      // get id of open modal
      self.modal = $(button).data("target");

      self.resetToInitialState();

      // open modal
      $(self.modal).modal("show");

      $("select[data-id=identity_provider]", self.modal).select2({
        dropdownParent: $(self.modal),
      });

      // assign data attribute to all tabs
      $(self.modal + " .wizard-pf-review-steps .list-group-item").each(
        function () {
          // set the first digit (i.e. n.0) equal to the index of the parent tab group
          // set the second digit (i.e. 0.n) equal to the index of the tab within the tab group
          $(this).attr("data-tab", $(this).index());
        }
      );

      // assign data attribute to all step indicator steps
      $(self.modal + " .wizard-pf-steps-indicator  .wizard-pf-step").each(
        function () {
          // set the value equal to the index of the tab group
          $(this).attr("data-tabgroup", $(this).index());
        }
      );

      // assign data attribute to all alt step indicator steps
      $(self.modal + " .wizard-pf-steps-alt .wizard-pf-step-alt").each(
        function () {
          // set the value equal to the index of the tab group
          var tabGroup = $(this).index();
          $(this).attr("data-tabgroup", tabGroup);
        }
      );

      // assign active and hidden states to the steps alt classes
      $(self.modal + " .wizard-pf-steps-alt-indicator").removeClass("active");
      $(self.modal + " .wizard-pf-steps-alt").addClass("hidden");
      $(self.modal + " .wizard-pf-steps-alt-indicator").on(
        "click",
        function () {
          $(self.modal + " .wizard-pf-steps-alt-indicator").toggleClass(
            "active"
          );
          $(self.modal + " .wizard-pf-steps-alt").toggleClass("hidden");
        }
      );
      $(self.modal + " .wizard-pf-step-alt > ul").addClass("hidden");

      // create array of all tabs, using the data attribute, and determine the last tab
      self.tabs = $(
        self.modal + " .wizard-pf-steps-indicator  .wizard-pf-step"
      ).map(function () {
        return $(this).data("tabgroup");
      });
      self.tabCount = self.tabs.length;
      self.tabSummary = self.tabs[self.tabCount - 1]; // last tab displays summary
      // set first tab group and tab as current tab
      // if someone wants to target a specific tab, that could be handled here
      self.currentGroup = 0;

      //initialize click listeners
      self.altStepClick();
      self.backBtnClicked();
      self.nextBtnClicked();
      self.cancelBtnClick();

      // Listen for required value change
      self.requiredInputsChange();

      // Handle form submit event
      self.formSubmitted();

      self.updateToCurrentPage();
    };

    // update which tab group is active
    this.updateTabGroup = function () {
      $(self.modal + " .wizard-pf-step.active").removeClass("active");
      $(
        self.modal +
          " .wizard-pf-step[data-tabgroup='" +
          self.currentGroup +
          "']"
      ).addClass("active");
      $(
        self.modal + " .list-group[data-tabgroup='" + self.currentGroup + "']"
      ).removeClass("hidden");
      $(self.modal + " .wizard-pf-step-alt")
        .not("[data-tabgroup='" + self.currentGroup + "']")
        .removeClass("active")
        .end()
        .filter("[data-tabgroup='" + self.currentGroup + "']")
        .addClass("active");
      $(self.modal + " .wizard-pf-step-alt > ul").addClass("hidden");
      $(
        self.modal +
          " .wizard-pf-step-alt[data-tabgroup='" +
          self.currentGroup +
          "'] > ul"
      ).removeClass("hidden");
    };

    // enable a button
    this.enableBtn = function ($el) {
      $el.removeClass("disabled").removeAttr("disabled");
    };

    // disable a button
    this.disableBtn = function ($el) {
      $el.addClass("disabled").attr("disabled", "disabled");
    };

    // update which tab is active
    this.updateActiveTab = function () {
      $(self.modal + " .list-group-item.active").removeClass("active");
      $(
        self.modal + " .list-group-item[data-tab='" + self.currentGroup + "']"
      ).addClass("active");

      self.updateVisibleContents();
    };

    // update which contents are visible
    this.updateVisibleContents = function () {
      var tabIndex = $.inArray(self.currentGroup, self.tabs);
      // displaying contents associated with currentGroup
      $(self.modal + " .wizard-pf-contents").addClass("hidden");
      $(self.modal + " .wizard-pf-contents:eq(" + tabIndex + ")").removeClass(
        "hidden"
      );
      // setting focus to first form field in active contents
      $(self.modal).one(
        "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
        function (event) {
          $(
            ".wizard-pf-contents:not(.hidden) form input, .wizard-pf-contents:not(.hidden) form textarea, .wizard-pf-contents:not(.hidden) form select"
          )
            .first()
            .focus(); // this does not account for disabled or read-only inputs
        }
      );
    };

    // update display state of Back button
    this.updateBackBtnDisplay = function () {
      var $backBtn = $(self.modal + " .wizard-pf-back");
      if (self.currentGroup == self.tabs[0]) {
        self.disableBtn($backBtn);
      } else {
        self.enableBtn($backBtn);
      }
    };

    // update display state of next/finish button
    this.updateNextBtnDisplay = function () {
      if (self.currentGroup == self.tabSummary) {
        $(self.modal + " .wizard-pf-next")
          .focus()
          .find(".wizard-pf-button-text")
          .text(gettext("Save"));
      } else {
        $(self.modal + " .wizard-pf-next .wizard-pf-button-text").text("Next");
      }
    };

    // update display state of buttons in the footer
    this.updateWizardFooterDisplay = function () {
      self.updateBackBtnDisplay();
      self.updateNextBtnDisplay();
    };

    this.updateToCurrentPage = function () {
      self.updateTabGroup();
      self.updateVisibleContents();
      self.updateWizardFooterDisplay();
    };

    this.altStepClick = function () {
      $(self.modal + " .wizard-pf-step-alt").each(function () {
        var $this = $(this);
        $(this)
          .find("> a")
          .on("click", function (e) {
            var subStepList = $this.find("> ul");
            if (subStepList && subStepList.length > 0) {
              $this.find("> ul").toggleClass("hidden");
            } else {
              self.currentGroup = $this.data("tabgroup");
            }
          });
      });
    };

    // Back button clicked
    this.backBtnClicked = function () {
      $("body").on("click", self.modal + " .wizard-pf-back", function () {
        // if not the first page
        if (self.currentGroup != self.tabs[0]) {
          // go back a page (i.e. -1)
          self.wizardPaging(-1);
          // show/hide/disable/enable buttons if needed
          self.updateWizardFooterDisplay();
        }
      });
    };

    this.nextTab = function () {
      if (self.currentGroup == self.tabSummary) {
        self.wizardPaging(1);
        self.finish();
      } else {
        // go forward a page (i.e. +1)
        self.wizardPaging(1);
        // show/hide/disable/enable buttons if needed
        self.updateWizardFooterDisplay();
      }
    };

    // Next button clicked
    this.nextBtnClicked = function () {
      $("body").on("click", self.modal + " .wizard-pf-next", function () {
        var $this = $(this);

        if (self.currentGroup == 0) {
          self.disableBtn($this);
          $(".wizard-pf-loading", this.modal).removeClass("hidden");
          $(".wizard-pf-contents:not(.hidden)", this.modal).addClass("hidden");
          var url =
            shibListAPI +
            "?metadata_url=" +
            $("input[data-id=metadata_location]", self.modal).val();
          $.get(url)
            .done(function (resp) {
              if (resp.count == 0) {
                topbar.notify(
                  "No identity providers available in the metadata",
                  "error"
                );
                return;
              }
              var selector = $("select[data-id=identity_provider]", self.modal);
              selector.empty();
              resp.items.forEach(function (item) {
                selector.append(
                  $(
                    '<option value="' + item.id + '">' + item.name + "</option>"
                  )
                );
              });
              selector.val(null).trigger("change");
              self.nextTab();
            })
            .fail(function () {
              topbar.notify("Error while fetching metadata", "error");
              self.wizardPaging(0);
            })
            .always(function () {
              self.enableBtn($(this));
              $(".wizard-pf-loading", this.modal).addClass("hidden");
            });
        } else if (self.currentGroup == 1) {
          $(
            ".wizard-pf-review-item.metadata .wizard-pf-review-item-value"
          ).html($("input[data-id=metadata_location]", self.modal).val());
          $(".wizard-pf-review-item.name .wizard-pf-review-item-value").html(
            $("select[data-id=identity_provider]", self.modal).select2("data")
              .text
          );
          self.nextTab();
        } else if (self.currentGroup == 2) {
          self.nextTab();
        }
      });
    };

    // Form submitted
    this.formSubmitted = function () {
      $("form", self.modal).on("submit", function (e) {
        e.preventDefault();
        $('button[type="submit"]:not(.disabled)').trigger("click");
      });
    };

    // Disable click events
    // 		$('body').on('click', $(self.modal + " .wizard-pf-step > a",
    // 			self.modal + " .wizard-pf-step-alt > a",
    // 			self.modal + " .wizard-pf-sidebar .list-group-item > a"), function(e) {
    // 			e.preventDefault();
    // 		});

    this.validateRequired = function ($el) {
      var $nextBtn = $(self.modal + " .wizard-pf-next"),
        $step = $(self.modal + " .wizard-pf-step"),
        $sidebarItem = $(
          self.modal +
            " .wizard-pf-sidebar .list-group-item:not(.wizard-pf-progress-link)"
        );

      if ($el.val()) {
        $step.removeClass("disabled");
        $sidebarItem.removeClass("disabled");
        self.enableBtn($nextBtn);
      } else {
        $step.not(".active").addClass("disabled");
        $sidebarItem.not(".active").addClass("disabled");
        self.disableBtn($nextBtn);
      }
    };

    this.requiredInputsChange = function () {
      var $el = $(
        ".form-group.required input:not(.select2-focusser):not(.select2-input)",
        self.modal
      );
      $el.on("change keyup load focus input", function () {
        self.validateRequired($(this));
      });
      $el = $(".form-group.required select", self.modal);
      $el.on("change", function () {
        self.validateRequired($(this));
      });
    };

    this.resetToInitialState = function () {
      // drop click event listeners
      $(self.modal + " .wizard-pf-steps-alt-indicator").off("click");
      $(self.modal + " .wizard-pf-step-alt > a").off("click");
      $(self.modal + " #detailsName").off("change");
      $("form", self.modal).off("submit");
      $("body").off("click");

      $(".wizard-pf-loading", this.modal).addClass("hidden");

      // reset final step
      $(self.modal + " .wizard-pf-process").removeClass("hidden");
      $(self.modal + " .wizard-pf-complete").addClass("hidden");
      // reset loading message
      // 		$(self.modal + " .wizard-pf-contents").addClass("hidden");
      // 		$(self.modal + " .wizard-pf-loading").removeClass("hidden");
      // remove tabs and tab groups
      // 		$(self.modal + " .wizard-pf-steps").addClass("hidden");
      // 		$(self.modal + " .wizard-pf-sidebar").addClass("hidden");
      // reset buttons in final step
      $(self.modal + " .wizard-pf-close").addClass("hidden");
      $(self.modal + " .wizard-pf-cancel").removeClass("hidden");
      $(self.modal + " .wizard-pf-next")
        .removeClass("hidden")
        .find(".wizard-pf-button-text")
        .text("Next");
      // reset input fields
      //$(self.modal + " .form-control").val("");
    };

    // Cancel/Close button clicked
    this.cancelBtnClick = function () {
      $(self.modal + " .wizard-pf-dismiss").click(function () {
        // close the modal
        $(self.modal).modal("hide");
        self.resetToInitialState();
      });
    };

    // when the user clicks Next/Back, then the next/previous tab and contents display
    this.wizardPaging = function (direction) {
      // get n.n value of next tab using the index of next tab in tabs array
      var groupIndex = $.inArray(self.currentGroup, self.tabs) + direction;
      var newTab = self.tabs[groupIndex];
      self.currentGroup = newTab;
      // remove active class from active tab in current tab group
      $(
        self.modal +
          " .list-group[data-tabgroup='" +
          self.currentGroup +
          "'] .list-group-item.active"
      ).removeClass("active");
      // apply active class to new current tab and associated contents
      self.updateTabGroup();
      self.updateActiveTab();
    };

    // This code keeps the same contents div active, but switches out what
    // contents display in that div (i.e. replaces process message with
    // success message).
    this.finish = function () {
      self.disableBtn($(self.modal + " .wizard-pf-back")); // if Back remains enabled during this step, then the Close button needs to be removed when the user clicks Back
      self.disableBtn($(self.modal + " .wizard-pf-next"));
      // disable progress link navigation
      $(self.modal + " .wizard-pf-step").addClass("disabled");
      $(self.modal + " .wizard-pf-step-alt").addClass("disabled");
      $(self.modal + " .wizard-pf-sidebar .list-group-item").addClass(
        "disabled"
      );
      // code for kicking off process goes here
      // the next code is just to simulate the expected experience, in that
      // when the process is complete, the success message etc. would display
      var metadata_location = $(
        "input[data-id=metadata_location]",
        self.modal
      ).val();
      var idp_data = $("select[data-id=identity_provider]", self.modal).select2(
        "data"
      );
      $.ajax(subsiteSettingsUrl, {
        method: "POST",
        data:
          "shib_metadata=" +
          metadata_location +
          "&shib_login_idp=" +
          idp_data.id +
          "&shib_login_name=" +
          idp_data.text,
      })
        .done(function (data) {
          topbar.notify("Configuration saved", "success");
          $(self.modal + " .wizard-pf-cancel").addClass("hidden");
          $(self.modal + " .wizard-pf-next").addClass("hidden");
          $(self.modal + " .wizard-pf-close").removeClass("hidden");
          $(self.modal + " .wizard-pf-process").addClass("hidden");
          $(self.modal + " .wizard-pf-complete").removeClass("hidden");
        })
        .error(function (jqXHR) {
          topbar.notify("Error while saving configuration");
        });
    };
  };

  return ssowizard;
});
