(function (scope) {
  function normalizeMap(map) {
    return Array.isArray(map)
      ? map.map(function (key) {
          return { key: key, val: key };
        })
      : Object.keys(map).map(function (key) {
          return { key: key, val: map[key] };
        });
  }

  function normalizeNamespace(fn) {
    return function (namespace, map) {
      if (typeof namespace !== "string") {
        map = namespace;
        namespace = "";
      } else if (namespace.charAt(namespace.length - 1) !== "/") {
        namespace += "/";
      }
      return fn(namespace, map);
    };
  }

  function getModuleByNamespace(store, helper, namespace) {
    var module = store._modulesNamespaceMap[namespace];
    if ("development" !== "production" && !module) {
      console.error(
        "[vuex] module namespace not found in " + helper + "(): " + namespace
      );
    }
    return module;
  }

  var mapGettersParam = normalizeNamespace(function (namespace, getters) {
    var res = {};
    normalizeMap(getters).forEach(function (ref) {
      var key = ref.key;
      var val = ref.val;

      val = namespace + val;
      res[key] = function mappedGetter() {
        if (
          namespace &&
          !getModuleByNamespace(this.$store, "mapGetters", namespace)
        ) {
          return;
        }
        if ("development" !== "production" && !(val in this.$store.getters)) {
          console.error("[vuex] unknown getter: " + val);
          return;
        }
        if (
          this.hasOwnProperty("gettersParam") &&
          typeof this.gettersParam === "function"
        ) {
          return this.$store.getters[val](this.gettersParam());
        } else {
          return this.$store.getters[val];
        }
      };
      // mark vuex getter for devtools
      res[key].vuex = true;
    });
    return res;
  });

  var attrGetter = function (attr) {
    return function (state) {
      if (!state.hasOwnProperty("attrGetterField")) {
        console.error("[vuex] attrGetterField getter missing");
        return;
      }
      return function (id) {
        return state[state.attrGetterField][id][attr];
      };
    };
  };

  Vuex.mapGettersParam = mapGettersParam;
  Vuex.attrGetter = attrGetter;

  var globalStore = (scope.globalStore = new Vuex.Store({
    strict: scope.vuexStrict,
  }));

  PortalVue.install(Vue);

  $(document).ready(function () {
    sculpteoNotification("vuex_module_refresh").recv(function (data) {
      var module = data.data["module"];
      var action = module + "/refresh";
      if (
        globalStore.state.hasOwnProperty(module) &&
        globalStore._actions[action]
      ) {
        globalStore.dispatch(action, data.data.data);
      }
    });
    sculpteoNotification("vuex_module_refresh_deleted").recv(function (data) {
      var module = data.data["module"];
      var action = module + "/refresh_deleted";
      if (
        globalStore.state.hasOwnProperty(module) &&
        globalStore._actions[action]
      ) {
        globalStore.dispatch(action, data.data.data);
      }
    });
  });
})(window);
